import React, { Fragment } from "react";

const Profile = () => {
  return (
    <Fragment>
      <p>Profile Page</p>
      <p>This is protected</p>
    </Fragment>
  );
};

export default Profile;
